import React from 'react';
import { Container, Button } from 'reactstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Tdsvideomodal from './Tdsvideomodal';

import './Tdsbanner.css';
class Tdsbanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                0: {
                    nav: false,
                    dots: true,
                },
                767: {
                    nav: true
                },

            },
        }

    }
    scrollToTop = (element) => {

        this.setState({
            activeMenu: element
        });

        var elementPosition = document.getElementById(element).offsetTop;
        window.scrollTo({
            top: elementPosition,
            behavior: "smooth"
        });
    }
    render() {
        return (

            <section className="banner">

                <OwlCarousel
                    className="banner-owl owl-theme"
                    loop={true}
                    margin={0}
                    autoplay={true}
                    autoplayTimeout={3000}
                    smartSpeed={500}
                    nav={true}
                    items="1"
                    responsive={this.state.responsive}
                    dots={false} >
                    <div className="item">
                        <div className="banner-one banner-img">
                            <Container>
                                <div className="banner-text text-center">

                              
                                    <h1 >GETTING A SOFTWARE JOB WITH OUR <span>INTERNSHIP PROGRAM</span>  </h1>
                                    <p>Regional, the best software training company in Calicut has proved its expertise by training and placing several hundreds of students </p>
                                    <div className="d-flex justify-content-center">
                                        <Button onClick={() => this.scrollToTop('contact')} color="primary mr-3">Get Started</Button>
                                        {/* <Tdsvideomodal></Tdsvideomodal> */}
                                    </div>

                                </div>
                            </Container>
                        </div>
                    </div>
                    {/* <div className="item">
                        <div className="banner-two banner-img">
                            <Container>
                                <div className="banner-text text-center">
                                    <h1 >THE BEST  <span>Digital Marketing</span>   & Branding</h1>
                                    <p>Learn programming from the most trusted software training company in Calicut with highest placement record. Enroll for software courses and internship programs in Python,PHP, Angular or Node.js to start your career.</p>
                                    <div className="d-flex justify-content-center">
                                        <Button color="primary mr-3">Get Started</Button>
                                        <Tdsvideomodal></Tdsvideomodal>
                                    </div>
                                </div>
                            </Container>
                        </div>

                    </div> */}
                </OwlCarousel>
            </section>
        );
    }
}


export default Tdsbanner;
