
import React from 'react';
import {

    Row, Col, Progress
} from 'reactstrap';
import Icofont from 'react-icofont';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Tdsaboutslider.css';

class Tdsaboutslider extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (

            <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav={false}
                items="1"
                dots={true}
                lazyLoad={true}

            >

<div className="item">
                    <div className="title-box text-left mb-0">
                        <h2 className="mb-0">Why Choose  <b>Us</b></h2>
                        {/* <h4 className="mt-0 mb-2 sub-title">We Are Digital <span>Marketing & Branding</span></h4> */}
                        <p>We help you to get the foot in the door. Come join with us and dive into world of opportunities.Experiment your ideas in real work environment under the guidance of experienced mentors and highly skilled professional trainers. Work along experts and speed up your learning process with the tips we provide. </p>
                    </div>
                    <div className="why-choose">
                        <Row noGutters>
                            <Col xs="6" sm="6" md="4">
                                <div className="why-box text-center">
                                    <Icofont icon="icofont-drag2" />
                                    <h5>Individual Attention</h5>
                                </div>
                            </Col>
                            <Col xs="6" sm="6" md="4">
                                <div className="why-box text-center">
                                    <Icofont icon="icofont-live-support" />
                                    <h5>Perfect Coding</h5>
                                </div>
                            </Col>
                            <Col xs="6" sm="6" md="4">
                                <div className="why-box text-center">
                                    <Icofont icon="icofont-support" />
                                    <h5>Friendly Support</h5>
                                </div>
                            </Col>
                            <Col xs="6" sm="6" md="4">
                                <div className="why-box text-center">
                                    <Icofont icon="icofont-unique-idea" />
                                    <h5>Unique Design</h5>
                                </div>
                            </Col>
                            <Col xs="6" sm="6" md="4">
                                <div className="why-box text-center">
                                    <Icofont icon="icofont-team" />
                                    <h5>Expert Team</h5>
                                </div>
                            </Col>
                            {/* <Col xs="6" sm="6" md="4">
                                <div className="why-box text-center">
                                    <Icofont icon="icofont-search-user" />
                                    <h5>SEO & Marketing</h5>
                                </div>
                            </Col> */}
                        </Row>
                    </div>
                </div>
                <div className="item">
                    <div className="title-box mb-0 text-left">
                        <h2 className="mb-0">About  <b>Regional</b></h2>
                        <h4 className="mt-0 mb-2 sub-title">We are Expert <span> trainers</span></h4>
                        <p> All the courses are designed by experts who are currently working in ths software industry. </p>
                    </div>
                    <Row className="mt-4" >
                        <Col className="text-left"><h6 className="mb-0">Fullstack Internship</h6></Col>
                        <Col className="text-right"><h6 className="mb-0">95%</h6></Col>
                    </Row>
                    <Progress value="95" animated color="orange" className="mb-3" />
                    <Row>
                        <Col className="text-left"><h6 className="mb-0">Flutter/React/Node</h6></Col>
                        <Col className="text-right"><h6 className="mb-0">95%</h6></Col>
                    </Row>
                    <Progress value={95} animated color="orange" className="mb-3" />
                    <Row>
                        <Col className="text-left"><h6 className="mb-0">IOT/Embded</h6></Col>
                        <Col className="text-right"><h6 className="mb-0">95%</h6></Col>
                    </Row>
                    <Progress value={95} animated color="orange" className="mb-3" />
                    <Row>
                        <Col className="text-left"><h6 className="mb-0">Php/Python/Android</h6></Col>
                        <Col className="text-right"><h6 className="mb-0">92%</h6></Col>
                    </Row>
                    <Progress value="92" animated color="orange" />

                </div>


                <div className="item">
                    <div className="title-box text-left mb-0">
                        <h2 className="mb-0">Know About Our   <span>Vision & Mission</span></h2>
                        {/* <h4 className="mt-0 mb-2 sub-title">We Are Digital <span>Marketing & Branding</span></h4> */}
                    </div>
                    <p>To see our self India’s most celebrated & 200- company status with unique value added contributions in information technology and services by 2020.

</p>
                 
                    <p>Our mission is to become a market leader by consistently exceeding our customer’s expectations; providing them with best breed of business solutions and deploy Information Technology potentials innovatively for the Clients’ unique business & competitive advantages. </p>
                   {/* <p>Simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                    <p>Word in classical literature, discovered the undoubtable source.  "de Finibus Bonorum et Malorum.</p>
                    <p>Standard chunk of Lorem Ipsum used since the 1500s is reproduced  "de Finibus Bonorum et Malorum" by Cicero.</p> */}

                </div>

               

            </OwlCarousel>

        );

    }



}

export default Tdsaboutslider;